import * as React from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from '@emotion/styled';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { useHistory } from 'react-router-dom';
import {
  AppStore,
  Competition,
  logout,
  refreshMainData,
  Settings,
} from '../state';
import gamifierLogo from '../imgs/gamifier.png';
import { FormControl, MenuItem, Select } from '@mui/material';
import { siteData } from '../util';
import { Drawer } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';

interface Props {
  competitions: Array<Competition>;
  hasMenu: boolean;
}

export default function TopBar(props: Props) {
  const { competitions } = props;
  const { user, settings } = AppStore.useState((s) => s);
  let { competitionId, competition } = AppStore.useState((s) => s);
  let [title, back, install] = AppStore.useState((s) => [
    s.title,
    s.back,
    s.install,
  ]);
  let history = useHistory();
  let { appVersion, serviceWorkerVersion } = AppStore.useState((s) => ({
    appVersion: s.appVersion,
    serviceWorkerVersion: s.serviceWorkerVersion,
  }));
  const loc = useLocation();

  let site = siteData();
  const [opened, { open, close }] = useDisclosure(false);
  React.useEffect(close, [loc]);

  return (
    <>
      <AppBar
        position="sticky"
        style={{
          background: settings.main_color,
          color: settings.main_text_color,
        }}
      >
        <Toolbar>
          {back != null && (
            <IconButton
              edge="start"
              color="inherit"
              aria-label="back"
              sx={{ ml: '-5px' }}
              onClick={() =>
                back === '' ? history.goBack() : history.push(back)
              }
            >
              <ArrowBackIosIcon />
            </IconButton>
          )}
          {props.hasMenu && (
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
              onClick={open}
            >
              <MenuIcon />
            </IconButton>
          )}
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            {title}
          </Typography>
          {site.staff && (
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={() =>
                AppStore.update((s) => {
                  s.editMode = !s.editMode;
                })
              }
            >
              <ModeEditIcon />
            </IconButton>
          )}
        </Toolbar>
      </AppBar>

      {props.hasMenu && (
        <Drawer
          className="menu"
          opened={opened}
          onClose={close}
          withCloseButton={false}
          zIndex={1100}
          size={'250px'}
        >
          <Nav settings={settings}>
            <img
              id="sidenav-close"
              onClick={close}
              src={require('../imgs/close.svg').default}
              width="30"
              height="30"
              alt="Close"
            />

            <Link to="/">Home</Link>
            {site.has_arena && <Link to="/arena">Arena</Link>}

            {site.v2 && (
              <>
                <Link to={`/competition/${competitionId}/teams`}>Teams</Link>
                <Link to={`/competition/${competitionId}/players`}>
                  Players
                </Link>
                <Link to={`/competition/${competitionId}/leaderboard`}>
                  {(!competition?.data?.is_league && 'Groups') || 'Ladder'}
                </Link>
                {user?.profile.has_performance_page_access && (
                  <Link to={`/competition/${competitionId}/performance`}>
                    Performance
                  </Link>
                )}
                {user?.profile.has_kpi_rank_page_access && (
                  <Link to={`/competition/${competitionId}/kpi_rank`}>
                    Team KPI Rank
                  </Link>
                )}
                {user?.profile.has_kpi_rank_page_access && (
                  <Link to={`/competition/${competitionId}/player_kpi_rank`}>
                    Player KPI Rank
                  </Link>
                )}
              </>
            )}

            {!site.v2 && (
              <>
                <Link to="/teams">Teams</Link>
                <Link to="/players">Players</Link>
                <Link to="/leaderboard">
                  {(!competition?.data?.is_league && 'Groups') || 'Ladder'}
                </Link>
                {user?.profile.has_performance_page_access && (
                  <Link to="/performance">Performance</Link>
                )}
              </>
            )}

            <Link to="/rules">Rules</Link>

            {site.pages
              .filter(
                (p) =>
                  p.competition_id === null ||
                  p.competition_id === competitionId
              )
              .map(({ slug, title }) => (
                <Link key={slug} to={`/page/${slug}`}>
                  {title}
                </Link>
              ))}

            <Link to="/settings">Settings</Link>

            <FormControl fullWidth>
              <Select
                className="selectCompetition"
                labelId="competition-select-label"
                id="competition-select"
                sx={{ maxWidth: '200px' }}
                value={competitionId}
                onChange={(event) => {
                  AppStore.update((s) => {
                    s.competitionId = event.target.value as number;
                  });
                  history.replace('/');
                  refreshMainData();
                }}
              >
                {competitions.map((c) => (
                  <MenuItem key={c.competition_id} value={c.competition_id}>
                    {c.is_visible ? c.name : `${c.name} (Restricted)`}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {install && (
              <div
                className="installApp"
                onClick={() => install.prompt && install.prompt()}
              >
                Install app
              </div>
            )}
            <div className="logout" onClick={logout}>
              Log out
            </div>
            <span id="version">
              <a
                href="https://www.gamifier.co.uk/"
                target="_blank"
                rel="noreferrer noopener"
              >
                <img src={gamifierLogo} alt="Gamifier Group" />
              </a>
              v{appVersion}
              {serviceWorkerVersion ? ` (${serviceWorkerVersion})` : ''}
            </span>
          </Nav>
        </Drawer>
      )}
    </>
  );
}

interface NavProps {
  settings: Settings;
}

const Nav = styled.nav<NavProps>`
  height: 100%;
  width: 250px;
  background-color: ${(props) => props.settings.main_color};
  padding-top: 70px;
  position: relative;
  display: flex;
  flex-direction: column;

  & > a,
  & > div {
    padding: 6px 8px 6px 20px;
    text-transform: uppercase;
    text-decoration: none;
    white-space: nowrap;
    font-size: 20px;
    color: ${(props) => props.settings.main_text_color};
    display: block;
  }

  .logout {
    margin-top: auto;
    margin-bottom: 40px;
    font-size: 20px;
    cursor: pointer;
  }

  .installApp {
    background: ${(props) => props.settings.main_text_color};
    border-radius: 5px;
    margin: 10px 20px;
    color: ${(props) => props.settings.main_color};
    height: 45px;
    line-height: 45px;
    font-size: 15px;
    font-weight: bold;
    text-align: center;
    padding: 0;
    cursor: pointer;
  }

  #sidenav-close {
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;
  }

  #version {
    display: flex;
    align-items: center;
    color: ${(props) => props.settings.main_text_color};
    position: absolute;
    left: 20px;
    bottom: 10px;
    font-size: 10px;
    opacity: 0.5;
  }

  #version img {
    display: inline;
    width: 16px;
    margin-right: 5px;
  }

  .selectCompetition {
    margin-top: 20px;
    color: ${(props) => props.settings.main_text_color};
  }
`;
