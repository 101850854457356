import { Favorite, MoreVert, Comment } from '@mui/icons-material';
import {
  Alert,
  Avatar,
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  CircularProgress,
  Collapse,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from '@mui/material';
import axios from 'axios';
import { useSnackbar, VariantType } from 'notistack';
import React, { useEffect, useRef, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useHistory } from 'react-router-dom';
import { queryClient } from '..';
import { AppStore, mediaAsset } from '../state';
import { dateFormatterFull, siteData } from '../util';
import { QueryLoader } from './Loader';
import PostCommentsComponent from './PostCommentsComponent';
import { invalidatePosts, PoppedPosts } from '../pages/WallFeed';
import confetti from 'canvas-confetti';
import styled from '@emotion/styled';
import { relative } from 'path';
import { useFade } from './FadeElement';

export interface Post {
  post_id: number;
  created_at: Date;
  file: string | null;
  text: string | null;
  user_id: number;
  player_id: number | null;
  player_name: string;
  player_photo: string;
  likes: number;
  comments: number;
  is_liked: boolean;
  file_format: string | null;
  is_validated: boolean | null;
  is_gong: boolean;
}

const getPostById = async (
  competitionId: number,
  postId: number
): Promise<Post> => {
  const { data } = await axios.get<Post>(
    `/api/competitions/${competitionId}/posts/${postId}`,
    { headers: { 'Gamifier-Platform': siteData().platform.toString() } }
  );
  return { ...data, ...{ created_at: new Date(data.created_at) } };
};

const useGetPost = (competitionId: number, postId: number) => {
  return useQuery(['post', competitionId, postId], () =>
    getPostById(competitionId, postId)
  );
};

export const invalidatePost = (competitionId: number, postId: number) => {
  queryClient.invalidateQueries(['post', competitionId, postId]);
};

interface Props {
  postId: number;
  poppedPost: PoppedPosts;
  setPoppedPost: React.Dispatch<React.SetStateAction<PoppedPosts>>;
}

function useOnScreen(ref: any) {
  const [isIntersecting, setIntersecting] = useState(false);

  const observer = new IntersectionObserver(([entry]) =>
    setIntersecting(entry.isIntersecting)
  );

  useEffect(() => {
    observer.observe(ref.current);
    // Remove the observer as soon as the component is unmounted
    return () => {
      observer.disconnect();
    };
  }, []);

  return isIntersecting;
}

export default function PostComponent(props: Props) {
  const { competitionId } = AppStore.useState((s) => s);
  const postApi = useGetPost(competitionId, props.postId);

  return (
    <QueryLoader
      data={postApi}
      loaded={(post) => <InnerComponent post={post} {...props} />}
    />
  );
}

interface InnerProps {
  post: Post;
  poppedPost: PoppedPosts;
  setPoppedPost: React.Dispatch<React.SetStateAction<PoppedPosts>>;
}

function InnerComponent(props: InnerProps) {
  const postId = props.post.post_id;
  const { competitionId } = AppStore.useState((s) => s);
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const player = AppStore.useState((s) => s.user?.player);
  const settings = AppStore.useState((s) => s.settings);
  const user = AppStore.useState((s) => s.user);
  const [postComment, setPostComment] = React.useState(false);
  const [refresh, setRefresh] = React.useState<NodeJS.Timer | null>(null);
  const ref = useRef(null);
  const isVisible = useOnScreen(ref);
  // pop only posts that are in viewport and valid for pop
  const isConfetti = isVisible && props.post.is_gong && props.post.is_validated;
  const isGong = props.post.is_gong;
  const postPopped = props.poppedPost[postId];
  const { setPoppedPost } = props;

  // TODO fix warning, infinite render if refresh is included
  useEffect(() => {
    if (props.post.is_validated === null) {
      setRefresh(
        setInterval(
          () => invalidatePost(competitionId, props.post.post_id),
          500
        )
      );
    } else {
      if (refresh !== null) {
        clearInterval(refresh);
      }
    }
  }, [competitionId, props.post.post_id, props.post.is_validated]);

  useEffect(() => {
    if (isConfetti && !postPopped) {
      // TODO: find a way to center pop animation on the component itself
      // currently pops in the middle of the screen
      confetti({ zIndex: 900 });
      // Set parent post popped state
      setPoppedPost((oldValue) => {
        let newValue = {} as PoppedPosts;
        newValue[postId] = true;
        return { ...oldValue, ...newValue };
      });
    }
  }, [isConfetti, postPopped, setPoppedPost]);

  const togglePostComment = () => {
    setPostComment(!postComment);
  };

  const isOwner = user ? user.id === props.post.user_id : false;

  const { enqueueSnackbar } = useSnackbar();

  const showSnackBar = (message: string, variant: VariantType) => {
    enqueueSnackbar(message, { variant });
  };

  const handleMenu = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDeletePost = async () => {
    try {
      await axios.delete(
        `/api/competitions/${competitionId}/posts/${props.post.post_id}`,
        {
          headers: {
            accept: 'application/json',
            'Gamifier-Platform': siteData().platform.toString(),
          },
        }
      );
      showSnackBar('Deleted post.', 'success');
      invalidatePosts(competitionId);
    } catch (e: any) {
      console.log(e);
      showSnackBar('Cannot delete the post at this time.', 'error');
    }
    handleClose();
  };

  const handleEditPost = () => {
    history.push('/arena/edit/', { post: props.post });
  };

  const handleLikePost = async () => {
    if (player !== undefined) {
      try {
        await axios.post(
          `/api/competitions/${competitionId}/posts/${props.post.post_id}/like`,
          null,
          {
            headers: {
              accept: 'application/json',
              'Gamifier-Platform': siteData().platform.toString(),
            },
          }
        );
        invalidatePost(competitionId, props.post.post_id);
      } catch (e: any) {
        showSnackBar('Cannot like/unlike post at this time.', 'error');
      }
    } else {
      showSnackBar('You cannot like this post.', 'info');
    }
  };

  let toolbar = isOwner ? (
    <Toolbar variant="dense" style={{ padding: 0 }}>
      <IconButton
        size="small"
        aria-label="My post options"
        aria-controls="menu-postbar"
        aira-aria-haspopup="true"
        onClick={handleMenu}
      >
        <MoreVert />
      </IconButton>
      <Menu
        id="menu-postbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleEditPost}>Edit</MenuItem>
        <MenuItem onClick={handleDeletePost}>Delete</MenuItem>
      </Menu>
    </Toolbar>
  ) : null;

  let is_video = props.post.file_format?.startsWith('video');
  let hasFile = props.post.file && props.post.file !== '';

  return (
    <Card
      ref={ref}
      variant="elevation"
      sx={
        props.post.is_gong
          ? {
              background: `${settings.main_color}29 !important`,
              border: `2px ${settings.main_color} solid !important`,
              aspectRatio: '16 / 9',
              display: 'flex',
              flexDirection: 'column',
              position: 'relative',
            }
          : {}
      }
      className={props.post.is_gong ? 'gong' : ''}
    >
      {props.post.is_gong && <RoarOverlay />}
      <CardHeader
        avatar={<Avatar src={mediaAsset(props.post.player_photo)} />}
        title={props.post.player_name}
        subheader={dateFormatterFull(props.post.created_at.getTime())}
        subheaderTypographyProps={{
          style: {
            fontSize: '0.75rem',
          },
        }}
        action={toolbar}
      />
      {(props.post.is_validated === null && (
        <>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              paddingBottom: '10px',
            }}
          >
            <CircularProgress />
          </Box>
          <Alert severity="info">
            Please wait until your video is processed.
          </Alert>
        </>
      )) ||
        (!props.post.is_validated && (
          <Alert severity="error">
            There was an error while processing video.
          </Alert>
        )) ||
        (hasFile &&
          ((is_video && (
            <CardMedia
              component="video"
              src={mediaAsset(props.post.file || '')}
              controls
            />
          )) || (
            <CardMedia
              component="img"
              src={mediaAsset(props.post.file || '')}
              alt="Post"
            />
          )))}
      <CardContent
        style={{
          paddingTop: hasFile ? '16px' : '0',
        }}
      >
        {props.post.text && (
          <Typography
            variant="body2"
            sx={
              isGong
                ? { fontSize: '20px', fontWeight: 'bold' }
                : { fontSize: '12px' }
            }
          >
            {props.post.text}
          </Typography>
        )}
      </CardContent>
      <CardActions sx={{ pl: '10px' }}>
        <IconButton sx={{ gap: '5px' }} size="small" onClick={handleLikePost}>
          <Favorite sx={{ color: props.post.is_liked ? '#ff3f3f' : 'gray' }} />
          <Typography variant="body2">{props.post.likes}</Typography>
        </IconButton>
        {!props.post.is_gong && (
          <IconButton
            sx={{ gap: '5px' }}
            size="small"
            onClick={togglePostComment}
          >
            <Comment />
            <Typography variant="body2">{props.post.comments}</Typography>
          </IconButton>
        )}
      </CardActions>
      <Collapse in={postComment} style={{ background: '#f4f4f4' }}>
        <PostCommentsComponent postId={props.post.post_id} />
      </Collapse>
    </Card>
  );
}

function RoarOverlay() {
  let isVisible = useFade();
  let roarBackground = AppStore.useState(
    (s) => s.settings.roar_post_flip_background_img
  );
  return (
    <RoarOverlayWrap
      className={isVisible && roarBackground ? '' : 'hidden'}
      style={{
        background: `no-repeat url('${roarBackground}') 0 0`,
        backgroundSize: 'cover',
      }}
    />
  );
}

let RoarOverlayWrap = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 800;
  top: 0;
  left: 0;
  background: red;
  opacity: 1;
  transition: opacity 0.7s ease-in-out;
  pointer-events: none;

  &.hidden {
    opacity: 0;
  }
`;
